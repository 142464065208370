import "./shs.css"
import "./home.css"
import { bannerIcon } from "./icons"

import gsap from "gsap"
import { useGSAP } from "@gsap/react"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"
import { useEffect, useRef, useState } from "react"
import S3 from "aws-sdk/clients/s3"
import AWS from "aws-sdk"
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share"
import {
  hashtag,
  location,
  offlineGuests,
  pairTicketHref,
  singleTicketHref,
} from "./constants"

gsap.registerPlugin(useGSAP)
gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(ScrollToPlugin)

const ShsApp = () => {
  const headerElRef = useRef()
  const [photos, setPhotos] = useState()
  const [showMenu, setShowMenu] = useState()
  const [guests, setGuests] = useState([])

  // Fetch guests
  useEffect(() => {
    const fetchGuests = async () => {
      try {
        const res = await fetch(
          "https://fetchguestlist-aavwf3xgbq-uc.a.run.app/"
        ).then((r) => r.json())
        setGuests(res.data)
      } catch (e) {
        console.log("Error fetching guest list", e)
      }
    }

    fetchGuests()
  }, [])

  // Animate
  useGSAP(() => {
    gsap.fromTo(
      ".ticket",
      {
        scale: 0,
        yPercent: 200,
        opacity: 0,
        rotate: 0,
      },
      {
        scale: 1,
        opacity: 1,
        duration: 1,
        ease: "power3.inOut",
        yPercent: 0,
        rotate: "-40deg",
      }
    )

    gsap.fromTo(
      ".intro-copy h1",
      {
        opacity: 0,
        yPercent: 100,
      },
      {
        opacity: 1,
        yPercent: 0,
        ease: "power3.inOut",
        duration: 1,
        delay: 0.3,
      }
    )

    gsap.fromTo(
      ".intro-copy h2",
      {
        opacity: 0,
        yPercent: 130,
      },
      {
        opacity: 1,
        yPercent: 0,
        ease: "power3.inOut",
        duration: 1,
        delay: 0.4,
      }
    )

    gsap.fromTo(
      ".intro-copy p",
      {
        opacity: 0,
        yPercent: 160,
      },
      {
        opacity: 1,
        yPercent: 0,
        ease: "power3.inOut",
        duration: 1,
        delay: 0.5,
      }
    )

    gsap.fromTo(
      ".intro-copy .register",
      {
        opacity: 0,
        scaleX: 0.85,
        yPercent: -55,
      },
      {
        opacity: 1,
        scaleX: 1,
        yPercent: 0,
        ease: "power3.inOut",
        duration: 1,
        delay: 0.75,
      }
    )

    gsap.fromTo(
      "header",
      {
        opacity: 0,
        yPercent: -55,
      },
      {
        opacity: 1,
        yPercent: 0,
        ease: "power3.inOut",
        duration: 1,
        delay: 0.5,
      }
    )

    gsap.fromTo(
      ".intro-banner",
      {
        yPercent: 200,
      },
      {
        yPercent: 0,
        ease: "power3.inOut",
        duration: 0.6,
        delay: 0.8,
      }
    )

    gsap.to(".intro-section", {
      scrollTrigger: {
        trigger: ".intro-section",
        top: "50%",
        onUpdate: (self) => {
          gsap.to(".intro-section .intro-banner-content", {
            xPercent: self.progress * 50,
            duration: 0,
          })
        },
      },
    })

    gsap.to("header", {
      scrollTrigger: {
        trigger: "section",
        top: 100,
        onUpdate: (self) => {
          const progress = self.progress
          if (progress > 0.6) {
            headerElRef.current.classList.add("bg")
          } else {
            headerElRef.current.classList.remove("bg")
          }
        },
      },
    })
  })

  const createBanner = () => {
    const bannerContent = []
    for (let i = 0; i < 20; i++) {
      bannerContent.push(
        <div key={i}>
          {bannerIcon} <h6>October 12th, 2024</h6>{" "}
          <span className="icon-arrow-up-right" />
        </div>
      )
    }

    return bannerContent
  }

  const getRandomArbitrary = (min, max) => {
    return Math.random() * (max - min) + min
  }

  // Fetch images from S3
  useEffect(() => {
    AWS.config.region = "us-east-1"
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: "us-east-1:901a6b01-96f7-4ee1-99ad-ae12b57a23fc",
    })

    const fetchPhotos = async () => {
      const client = new S3({
        params: { Bucket: "shs-1974-photos" },
      })

      client.listObjectsV2((err, data) => {
        let selected = []
        if (data?.Contents?.length > 18) {
          const shuffled = data.Contents.sort(() => 0.5 - Math.random())
          selected = shuffled.slice(0, 18)
        } else if (data?.Contents?.length > 0) {
          selected = data.Contents
        }

        setPhotos(
          selected.map((s) => ({
            src: `https://shs-1974-photos.s3.amazonaws.com/${s.Key}`,
            id: s.ETag,
            speed: getRandomArbitrary(0.98, 1.03),
          }))
        )
      })
    }

    fetchPhotos()
  }, [])

  // Animate images
  useGSAP(() => {
    if (photos?.length < 1) return

    setTimeout(() => {
      gsap.to(".photo-container", {
        y: (i, el) =>
          (1 - parseFloat(el.getAttribute("data-speed"))) *
          ScrollTrigger.maxScroll(window),
        skewY: (i, el) =>
          (1 - parseFloat(el.getAttribute("data-speed"))) *
          ScrollTrigger.maxScroll(window) *
          0.03,
        ease: "none",
        scrollTrigger: {
          start: 0,
          end: "max",
          invalidateOnRefresh: true,
          scrub: 0,
        },
      })
    }, 500)
  }, [photos])

  const registerButton = (
    <div className="register">
      <div className="register-options">
        <a href={singleTicketHref} target="_blank" rel="noreferrer">
          <img src="/ticket.png" alt="SHS Class of 1974 Ticket" /> Single Ticket
        </a>
        <a href={pairTicketHref} target="_blank" rel="noreferrer">
          <img src="/ticket.png" alt="SHS Class of 1974 Ticket" />
          <img src="/ticket.png" alt="SHS Class of 1974 Ticket" /> Pair of
          Tickets
        </a>
      </div>
      <button>Register Now!</button>
    </div>
  )

  const handleScrollTo = (section) => {
    gsap.to(window, {
      duration: 0.75,
      scrollTo: {
        y: `section.${section}`,
        offsetY: 100,
      },
    })
    setShowMenu(false)
  }

  const getShareContainer = () => {
    const url = window.location.href
    const subject =
      "Register now for the Statesville High School Class of 1974 Reunion!"

    return (
      <div className="sharing">
        <EmailShareButton subject={subject} url={url}>
          <span className="icon-mail" />
        </EmailShareButton>
        <FacebookShareButton hashtag={hashtag} url={url}>
          <span className="icon-facebook" />
        </FacebookShareButton>
        <LinkedinShareButton title={subject} url={url}>
          <span className="icon-linkedin" />
        </LinkedinShareButton>
        <TwitterShareButton url={url} hashtags={[hashtag]} title={subject}>
          <span className="icon-twitter" />
        </TwitterShareButton>
      </div>
    )
  }

  return (
    <div className="shs-app">
      {showMenu && (
        <div className="mobile-menu">
          <button className="close-btn" onClick={() => setShowMenu(false)}>
            <span className="icon-x" />
          </button>
          <ul>
            <li onClick={() => handleScrollTo("schedule")}>Schedule</li>
            <li onClick={() => handleScrollTo("location")}>Location</li>
            <li onClick={() => handleScrollTo("guests")}>Who's Coming?</li>
            <li onClick={() => handleScrollTo("news")}>Photo Feed</li>
          </ul>
          {registerButton}
          {getShareContainer()}
        </div>
      )}
      <header ref={headerElRef}>
        <div className="header-content">
          <img
            src="/ticket-h.png"
            alt="SHS Class of 1974 Reunion -  Saturday October 12th"
          />
          <ul>
            <li onClick={() => handleScrollTo("schedule")}>Schedule</li>
            <li onClick={() => handleScrollTo("location")}>Location</li>
            <li onClick={() => handleScrollTo("guests")}>Who's Coming?</li>
            <li onClick={() => handleScrollTo("news")}>Photo Feed</li>
          </ul>
          <button
            className="menu-btn mobile-only"
            onClick={() => setShowMenu(true)}
          >
            <span className="icon-arrow-up-right" /> Menu
          </button>
          {getShareContainer()}
          {registerButton}
        </div>
      </header>
      <section className="intro-section">
        <div className="intro-copy">
          <h1>Statesville High School Class of 1974 Reunion</h1>
          <h2>
            Welcome! We look forward to seeing you{" "}
            <strong>Saturday October 12th, 2024</strong> in Statesville, NC for
            the Class of 1974 Reunion.
          </h2>
          <p>
            Explore this site to learn more about the venue, schedule and stay
            tuned for any updates.
          </p>
          <p>Register now and reserve a spot!</p>
          {registerButton}
        </div>
        <img src="/ticket.png" alt="SHS Class of 1974" className="ticket" />
        <div className="intro-banner">
          <div className="intro-banner-content">
            {createBanner().map((c) => c)}
          </div>
        </div>
      </section>
      <section className="section schedule">
        <h2>
          <span className="icon-calendar" /> Schedule
        </h2>
        <br />
        <p>Heavy hors d’oeuvres and drinks from 7:00 - 10:00 PM</p> <br />{" "}
        <p>
          Pricing: <strong>$35 per person</strong>
        </p>
        <br />
        <p>
          <strong>
            * Please note: Our host asks us to cutoff reservations on September
            25th so please reserve your spot with this in mind.
          </strong>
        </p>
      </section>
      {photos?.length > 0 && (
        <section className="photo-grid">
          {photos.map((p) => {
            return (
              <div key={p.id} data-speed={p.speed} className="photo-container">
                <img
                  src={p.src}
                  alt="Statesville High School Class of 1974 Reunion"
                />
              </div>
            )
          })}
        </section>
      )}
      <section className="section dark location">
        <h2>
          <span className="icon-map" /> Location
        </h2>
        <br />
        <p>{location}</p>
      </section>
      <section className="section guests">
        <h2>
          <span className="icon-user" /> Who's Coming?
        </h2>
        <br />
        {offlineGuests.map((g) => {
          return <p key={g}>{g}</p>
        })}
        {guests?.map((g) => {
          return <p key={g}>{g}</p>
        })}
      </section>
      <section className="section news">
        <h2>
          <span className="icon-instagram" /> Photo Feed
        </h2>
        <p>Use Instagram hashtag #{hashtag}</p>
        <div
          className="embedsocial-hashtag"
          data-ref="561ae724cd726e92353ed151609784e5507ba660"
        />
      </section>
      <div className="intro-banner static">
        {" "}
        <div className="intro-banner-content">
          {createBanner().map((c) => c)}
        </div>
      </div>
      <footer>
        <div className="footer-flex">
          <div>
            <h3>Statesville High School Class of 1974 Reunion</h3>
            <p>Saturday October 12th, 2024, 7:00 PM</p>
            <p>{location}</p>
          </div>
          <div>
            <img
              src="/ticket-h.png"
              alt="SHS Class of 1974"
              className="footer-ticket"
            />
          </div>
        </div>
        <div className="legal">&copy; 2024 Statesville Planning Committee</div>
      </footer>
    </div>
  )
}

export default ShsApp
