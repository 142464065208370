export const location =
  "The Twisted Oak American Bar & Grill, 121 N. Center Street #104, Statesville NC 28677"

export const hashtag = "SHSHounds74"

export const singleTicketHref = "https://buy.stripe.com/8wM4hJb978G25K8fYY"
export const singleTicketHrefTest =
  "https://buy.stripe.com/test_7sI0367oe8EmexG9AD"

export const pairTicketHref = "https://buy.stripe.com/eVacOf3GFe0mgoM3cd"
export const pairTicketHrefTest =
  "https://buy.stripe.com/test_6oE5nq23U2fY89i004"

export const offlineGuests = [
  "Jeff Corbett",
  "Ruth Harris Larkin",
  "John Larkin",
  "Toni Shuford",
]
